import { useState } from 'react';
import { useRouter } from 'next/router';

import { useFastTrack } from 'hooks/useFastTrack';
import { fastTrackMastheadFormProps } from 'humanity/components/fastTrackMastheadForm/fastTrackMastheadForm.types.js';
import getOrSetTrackingParams from 'utils/getOrSetTrackingParams';
import useAnalytics from 'hooks/useAnalytics';
import ContentfulForm from 'humanity/components/contentfulForm';
import SignupModal from 'features/signup/components/signupModal';
import { logger } from 'utils/logger';

const FastTrackMastheadForm = ({ form, variant, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    stepsCollection: { items: steps },
    hubspotFormId,
    hubspotSandboxFormId,
    salesforceCampaignId,
  } = form;
  // We only get/display inputs from the first step in the flow here
  const inputs = steps[0]?.inputsCollection?.items;
  const bottomText = steps[0]?.bottomText?.json;
  const {
    initialValues,
    handleNextStep,
    bookingUrl,
    fromGusto,
    showModal,
    onModalClose,
  } = useFastTrack({
    steps,
    formMetadata: {
      formType: 'Signup',
      hubspotFormId,
      hubspotSandboxFormId,
      salesforceCampaignId,
    },
  });

  const router = useRouter();
  const trackingParams = getOrSetTrackingParams(router.query);

  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const userData = {
        ...values,
        ...trackingParams,
        hinAnonymousId,
        leadSource: 'Inbound',
        stepNumber: 1,
      };

      const shouldRedirect = await handleNextStep(userData);

      if (shouldRedirect) {
        const { employerId, planId } = router.query;
        const queryParams = {};
        if (employerId) {
          queryParams.employerId = employerId;
        }
        if (planId) {
          queryParams.planId = planId;
        }

        router.push({
          pathname: '/signup/fast-track/',
          query: queryParams,
        });
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      logger.critical('Error submitting Fast Track form', err);
      setIsLoading(false);
    }
  };

  return (
    <>
      {initialValues && (
        <ContentfulForm
          data-testid="FastTrackMastheadForm"
          formId={form.sys?.id}
          fromGusto={fromGusto}
          inputs={inputs}
          initialValues={initialValues}
          bottomText={bottomText}
          onSubmit={handleSubmit}
          // TODO: may need to add support for these fields somehow
          // submitButtonText={form.submitButtonText}
          submitButtonVariant="primary"
          // disclosures={form.disclosures}
          isLoading={isLoading}
          variant={variant}
          {...rest}
        />
      )}
      <SignupModal bookingUrl={bookingUrl} isOpen={showModal} onClose={onModalClose} />
    </>
  );
};

FastTrackMastheadForm.propTypes = {
  ...fastTrackMastheadFormProps,
};

export default FastTrackMastheadForm;
